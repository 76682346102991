export default {
  methods: {
    removeAccents(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    filterWithoutAccents(option, label, search) {
      const optionText = this.removeAccents(label.toLowerCase())
      const queryText = this.removeAccents(search.toLowerCase())
      return optionText.includes(queryText)
    },
  },
}
