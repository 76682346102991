<template>
  <b-form
    @submit.prevent="save"
  >
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.section.personal_info') }}</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <b-col
              cols="4"
              md="4"
              lg="4"
            >
              <!-- Media -->
              <b-media>
                <b-col
                  cols="12"
                  md="12"
                  class="text-center mb-1"
                >
                  <b-img
                    :src="avatar"
                    text=""
                    variant="light-success"
                    class="border"
                    fluid
                  />
                </b-col>
                <div class="mb-1">
                  <div class="mb-1">
                    <b-button
                      variant="primary"
                      @click="$refs.refInputAvatar.click()"
                    >
                      <input
                        ref="refInputAvatar"
                        type="file"
                        class="d-none"
                        @change="handleAvatarImage()"
                      >
                      <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.object.updateAvatar') }}</span>
                      <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                  </div>
                  <div class="mb-1">
                    <b-button
                      variant="danger"

                      @click="removeAvatarImage()"
                    >
                      <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.object.deleteAvatar') }}</span>
                      <feather-icon
                        icon="TrashIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                  </div>
                </div>
              </b-media>
            </b-col>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="documentType"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.documentType') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.documentType')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.document_type_id"
                    :options="documentTypeList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="3"
              xl="3"
            >
              <b-form-group
                label-for="identity"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.identity') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.identity')"
                  rules="required"
                >
                  <b-form-input
                    id="identity"
                    v-model="elementData.identity"
                    maxlength="20"
                    @blur="fetchElementIdentity"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="first_name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.first_name') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.first_name')"
                  rules="required"
                >
                  <b-form-input
                    id="first_name"
                    v-model="elementData.first_name"
                    maxlength="60"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="second_name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.second_name') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.second_name')"
                >
                  <b-form-input
                    id="second_name"
                    v-model="elementData.second_name"
                    maxlength="60"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="first_surname"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.first_surname') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.first_surname')"
                  rules="required"
                >
                  <b-form-input
                    id="first_surname"
                    v-model="elementData.first_surname"
                    maxlength="60"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="second_surname"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.second_surname') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.second_surname')"
                >
                  <b-form-input
                    id="second_surname"
                    v-model="elementData.second_surname"
                    maxlength="60"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-row class="d-flex">
        <b-col
          cols="12"
          md="12"
        >
          <template v-if="elementData">
            <contacts-edit-tab
              :new-element="newElement"
              :element-data="elementData"
              :contact-type-list="contactTypeList"
              :department-list="departmentList"
              :city-list="cityList"
              :relationship-list="relationshipList"
              :population-zone-list="populationZoneList"
              :is-patient="isPatient"
              class="mt-2 pt-75"
            />
          </template>
        </b-col>
      </b-row>

      <b-row class="d-flex">
        <b-col
          cols="12"
          md="12"
        >
          <template v-if="elementData">
            <guardians-edit-tab
              :new-element="newElement"
              :element-data="elementData"
              :document-type-list="documentTypeList"
              :contact-type-list="contactTypeList"
              :relationship-list="relationshipList"
              class="mt-2 pt-75"
            />
          </template>
        </b-col>
      </b-row>
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.section.origin_data') }}</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="birth_country"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.birth_country') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.birth_country')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.birth_country_id"
                    :options="countryList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              v-if="elementData"
              cols="3"
              xl="3"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t(resourcesElementName + '.object.birthdate')"
                rules="required"
              >
                <date-picker
                  :element-required="true"
                  element-field="birthdate"
                  :root-element-data="elementData"
                  :element-label="$t(resourcesElementName + '.object.birthdate')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="3"
              xl="3"
            >
              <b-form-group
                v-if="elementData.birthdate"
                label-for="age"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.age') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.age')"
                  rules="required"
                >
                  <b-form-input
                    id="age"
                    v-model="age"
                    maxlength="3"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.section.demographic_data') }}</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="sex"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.sex') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.sex')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.sex_id"
                    :options="sexList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="genre"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.genre') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.genre')"
                >
                  <v-select
                    v-model="elementData.genre_id"
                    :options="genreList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.section.job_data') }}</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="employment"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.employment') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.employment')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.employment_id"
                    :options="filteredEmploymentList"
                    label="value"
                    value="value"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="3"
              xl="3"
            >
              <b-form-group
                label-for="eapb_eps"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.eapb_eps') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.eapb_eps')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.eapb_eps_id"
                    :options="epsList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-row class="d-flex justify-content-end">
        <b-button
          v-if="!isModal"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          v-else
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="hideModal()"
        >
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid"
        >
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>

</template>

<script>

import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm, BImg, BMedia,
} from 'bootstrap-vue'
import { required } from '@validations'
import GuardiansEditTab from '@/views/patient/edit/EditGuardians.vue'
import ContactsEditTab from '@/views/core/contact/EditContacts.vue'
import DatePicker from '@/views/core/DatePicker.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import router from '@/router'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    DatePicker,
    ContactsEditTab,
    GuardiansEditTab,
    BImg,
    BMedia,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    isModal: {
      type: Boolean,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    epsList: {
      type: Array,
      required: true,
    },
    countryList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    cityList: {
      type: Array,
      required: true,
    },
    sexList: {
      type: Array,
      required: true,
    },
    employmentList: {
      type: Array,
      required: true,
    },
    arlList: {
      type: Array,
      required: true,
    },
    documentTypeList: {
      type: Array,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
    genreList: {
      type: Array,
      required: true,
    },
    elementAvatar: {
      type: String,
      required: true,
    },
    relationshipList: {
      type: Array,
      required: true,
    },
    populationZoneList: {
      type: Array,
      required: true,
    },
    isPatient: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      avatarUpdated: false,
      avatar: null,
    }
  },
  computed: {
    age: {
      get() {
        if (this.elementData.birthdate) {
          let date = this.elementData.birthdate
          if (!moment.isMoment(date)) {
            date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
          }
          return moment().diff(date, 'years')
        }
        return null
      },
    },
    filteredEmploymentList() {
      let employmentOptions = this.employmentList.filter(option => option.coba_state !== 0);

      if (this.elementData.employment && this.elementData.employment.id) {
        const currentEmployment = this.elementData.employment;
        
        if (!employmentOptions.some(option => option.id === currentEmployment.id)) {
          employmentOptions.push({
            id: currentEmployment.id,
            value: currentEmployment.value,
            coba_state: 0
          });
        }
      }

      return employmentOptions;
    }
  },
  watch: {
    elementAvatar(newValue, oldValue) {
      this.avatar = newValue
    },
  },
  mounted() {
    this.avatar = this.elementAvatar
  },
  methods: {
    router() {
      return router
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-patient')
    },
    handleAvatarImage() {
      this.createAvatarBase64mage(this.$refs.refInputAvatar.files[0])
      this.avatarUpdated = true
    },
    createAvatarBase64mage(Avatar) {
      const reader = new FileReader()
      reader.onload = e => {
        this.avatar = e.target.result
      }
      reader.readAsDataURL(Avatar)
    },
    removeAvatarImage() {
      this.avatarUpdated = true
      this.avatar = ''
      this.elementData.avatar_id = null
      this.$refs.refInputAvatar.value = ''
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          let ok = false
          const address_type = this.contactTypeList.find(element => element.key === 'HOME_ADDRESS').id
          const office_address_type = this.contactTypeList.find(element => element.key === 'OFFICE_ADDRESS').id
          for (const contact of this.elementData.contacts) {
            if (contact.type_id === address_type || contact.type_id ===office_address_type) {
              ok = true
            }
          }
          if (ok) {
            if (this.avatar && this.avatarUpdated) {
              this.elementData.avatar = this.avatar
            }
            if (this.isModal) {
              _self.$root.$emit('save-patient', _self.elementData)
              _self.$root.$emit('bv::hide::modal', 'modal-patient')
            } else {
              this.$parent.save(this.elementData)
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: _self.$t('patient.action_results.contact.title'),
                icon: 'SaveIcon',
                variant: 'danger',
                text: _self.$t('patient.action_results.contact.failure'),
              },
            })
          }
        }
      })
    },
    fetchElementIdentity() {
      // this.newElement = false
      console.info('fetchElementIdentity')
      if (this.elementData.identity) {
        this.$root.$emit('fetch-element-identity', this.elementData.identity)
      }
    },
  },
}
</script>

<style>

</style>
