import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchImage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/image/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_digital_file(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/digital_file/digital_file_record/patient/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_digital_file_case(ctx, {id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/digital_file/digital_file/case/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/patient`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_excel_elements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/patient/excel`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_case(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
        .get(`/api/v1/cases/case/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/patient/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_element_identity(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/patient_by_identity`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/cases/patient`, elementData.data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/patient/${elementData.data.id}`, elementData.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchArls(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { 
            params: { perPage: 500, page: 1, companyType: 'ARL' } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDocumentTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/document_type', { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value(ctx, { type }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/' + type, { 
            params: { deep: 1 }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchContactTypes(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/contact_type', { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSexs(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/sex', { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGenres(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/genre', { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmployments(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/employment', { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepartments(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 2 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, {parent}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 3, parent: parent } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_labs(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { 
            params: { perPage: 500, page: 1, companyType: 'LABORATORIES', only_active: true } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_lab_tests(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/labtest', { 
            params: params.queryParams
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRelationships(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/relationship', { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_case_date(ctx, { step_id }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`/api/v1/process/case_date/step/${step_id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchInvoiceSupports(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/billing/invoice_support/case/${params.case_id}`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/billing/invoice/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_patient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/patient/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoiceItems(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/billing/search/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoiceStatus(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/invoice_status', { params: { perPage: 50, page: 1 } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
