<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.section.main_information') }}</span>
      </div>
    </b-card-header>
    <b-card-body>

      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-form
          @submit.prevent="save"
        >
          <b-row>
            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="12"
            >
              <b-form-group
                label-for="name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.name') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.name')"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="elementData.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="documentType"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.documentType') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.documentType')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.document_type"
                    :options="documentTypeList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="identity"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.identity') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.identity')"
                  rules="required"
                >
                  <b-form-input
                    id="identity"
                    v-model="elementData.identity"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Right Col: Table -->
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="phone"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.phone') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.phone')"
                >
                  <b-form-input
                    id="phone"
                    v-model="elementData.phone"
                    maxlength="25"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="email"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.email') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.email')"
                  rules="email"
                >
                  <b-form-input
                    id="email"
                    v-model="elementData.email"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row id="relationship">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="relationship"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.relationship') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.relationship')"
                >
                  <v-select
                    v-model="elementData.relationship_id"
                    :options="relationshipList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="$t('list.defaultOption')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-end">
            <b-button
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="hideModal()"
            >
              {{ $t('actions.back') }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
            >
              {{ $t('actions.save') }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    BCard, BButton, BRow, BCol, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    documentTypeList: {
      type: Array,
      required: true,
    },
    relationshipList: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      required,
      resourcesElementName: 'guardian',
    }
  },
  methods: {
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          _self.$root.$emit('save-guardian', _self.elementData)
          _self.$root.$emit('bv::hide::modal', 'modal-1')
        }
      })
    },
  },
}
</script>

<style>

</style>
