<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.section.main_information') }}</span>
      </div>
    </b-card-header>
    <b-card-body>

      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-form
          @submit.prevent="save"
        >
          <b-row>
            <b-col
              cols="8"
              md="8"
            >
              <b-form-group
                label-for="type"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.type') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.type')"
                  rules="required"
                >
                  <div class="v-select-wrapper">
                    <v-select
                      v-model="elementData.type_id"
                      :options="contactTypeList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="getPlaceholderValue(contactTypeList)"
                      :filter-by="filterWithoutAccents"
                      @input="change_type"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="12"
            >
              <b-form-group
                label-for="data"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + labelData) }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + labelData)"
                  rules="required"
                >
                  <b-form-input
                    id="data"
                    v-model="elementData.data"
                    :pattern="data_pattern"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row id="geography">
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="department"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.department') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.department')"
                >
                  <div class="v-select-wrapper">
                    <v-select
                      v-model="elementData.department_id"
                      :options="departmentList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="getPlaceholderValue(departmentList)"
                      :filter-by="filterWithoutAccents"
                      @input="onDepartmentChange"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="city"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.city') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.city')"
                >
                  <div class="v-select-wrapper">
                    <v-select
                      v-model="elementData.city_id"
                      :options="cityList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="getPlaceholderValue(cityList)"
                      :filter-by="filterWithoutAccents"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="isPatient && showAddressData">
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="town"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.town') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.town')"
                  rules="required"
                >
                  <b-form-input
                    id="town"
                    v-model="elementData.town"
                    maxlength="100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="population_zone"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.population_zone') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.population_zone')"
                >
                  <div class="v-select-wrapper">
                    <v-select
                      v-model="elementData.population_zone_id"
                      :options="populationZoneList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="getPlaceholderValue(populationZoneList)"
                      :filter-by="filterWithoutAccents"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="isPatient"
            id="relationship"
          >
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="relationship"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.relationship') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.relationship')"
                >
                  <div class="v-select-wrapper">
                    <v-select
                      v-model="elementData.relationship_id"
                      :options="relationshipList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="getPlaceholderValue(relationshipList)"
                      :filter-by="filterWithoutAccents"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-end">
            <b-button
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="hideModal()"
            >
              {{ $t('actions.back') }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
            >
              {{ $t('actions.save') }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormInput, BFormGroup, BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import filtersMixin from '@/libs/filtersMixin'

export default {
  components: {
    BCard, BButton, BRow, BCol, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, vSelect,
  },
  mixins: [filtersMixin],
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    cityList: {
      type: Array,
      required: true,
    },
    isPatient: {
      type: Boolean,
      required: false,
    },
    relationshipList: {
      type: Array,
      required: false,
    },
    populationZoneList: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      required,
      resourcesElementName: 'contact',
      data_pattern: '.{1,100}',
      labelData: '.object.data',
    }
  },
  computed: {
    showAddressData() {
      const new_element = this.elementData.type_id
      const contactType = this.contactTypeList.find(element => new_element === element.id)
      return !!(new_element && contactType.key !== 'EMAIL' && contactType.key !== 'PHONE' && contactType.key !== 'CELL_PHONE');
    },
  },
  mounted() {
    this.change_type(this.elementData.type_id)
    this.$root.$emit('fetch-cities', this.elementData.department_id)
  },
  methods: {
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-2')
    },
    change_type(new_element) {
      const element = document.getElementById('geography')
      element.classList.add('hidden-div')
      const contactType = this.contactTypeList.find(element => new_element === element.id)
      if (new_element) {
        if (contactType.key === 'EMAIL') {
          this.data_pattern = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'
          this.labelData = '.object.contact_email'
        } else if (contactType.key === 'PHONE') {
          this.data_pattern = '.{1,25}'
          this.labelData = '.object.contact_number'
        } else if (contactType.key === 'CELL_PHONE') {
          this.data_pattern = '.[0-9]{9,12}$'
          this.labelData = '.object.contact_number'
        } else if (contactType.key === 'HOME_ADDRESS' || contactType.key === 'OFFICE_ADDRESS') {
          element.classList.remove('hidden-div')
          this.data_pattern = '.{1,80}'
          this.labelData = '.object.contact_address'
        } else {
          element.classList.remove('hidden-div')
          this.data_pattern = '.{1,80}'
          this.labelData = '.object.data'
        }
      }
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          _self.$root.$emit('save-contact', _self.elementData)
          _self.$root.$emit('bv::hide::modal', 'modal-2')
        }
      })
    },
    onDepartmentChange() {
      this.elementData.city_id = null
      this.$root.$emit('fetch-cities', this.elementData.department_id)
    },
    getPlaceholderValue(list) {
      const placeholderOption = list.find(option => option.id === null)
      return placeholderOption ? placeholderOption.value : ''
    },
  },
}
</script>

<style>

.hidden-div{
    display: none;
  }
</style>
