var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.section.main_information')))])],1)]),_c('b-card-body',[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"12"}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.name')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.elementData.name),callback:function ($$v) {_vm.$set(_vm.elementData, "name", $$v)},expression:"elementData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"documentType"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.documentType')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.documentType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.documentTypeList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.elementData.document_type),callback:function ($$v) {_vm.$set(_vm.elementData, "document_type", $$v)},expression:"elementData.document_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label-for":"identity"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.identity')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.identity'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"identity"},model:{value:(_vm.elementData.identity),callback:function ($$v) {_vm.$set(_vm.elementData, "identity", $$v)},expression:"elementData.identity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"phone"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.phone')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","maxlength":"25"},model:{value:(_vm.elementData.phone),callback:function ($$v) {_vm.$set(_vm.elementData, "phone", $$v)},expression:"elementData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"email"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.email')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.email'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email"},model:{value:(_vm.elementData.email),callback:function ($$v) {_vm.$set(_vm.elementData, "email", $$v)},expression:"elementData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{attrs:{"id":"relationship"}},[_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"relationship"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.relationship')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.relationship')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.relationshipList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.elementData.relationship_id),callback:function ($$v) {_vm.$set(_vm.elementData, "relationship_id", $$v)},expression:"elementData.relationship_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",on:{"click":function($event){return _vm.hideModal()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.back'))+" ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('actions.save'))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }